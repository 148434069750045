import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { TextField } from '@material-ui/core';
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Entity } from '@backstage/catalog-model';

export interface EntityPickerProps {
  kind: string;
  placeholder?: string;
  label: string;
  onValueChange: (ref: Entity | null) => void;
}

export const EntityPicker = (props: EntityPickerProps) => {
  const { kind, onValueChange } = props;
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entities,
    loading,
    error,
  } = useAsync(() => {
    return catalogApi.getEntities({
      filter: {
        kind,
      },
    });
  }, [kind]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.message}</div>;

  const filteredEntities = entities?.items ?? [];

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={o => o.metadata.title || o.metadata.name}
      onChange={(_, newValue) => onValueChange(newValue)}
      options={filteredEntities}
      renderInput={params => <TextField {...params} label="Products" />}
    />
  );
};
