import type { JsonObject } from '@backstage/types';
import {
  QuestionnaireCategory,
  StepCollection,
  ValueMapping,
} from './Review/QuestionnaireResults/schema';

export interface OutcomePillar {
  name: string;
  title: string;
  outcomes: Outcome[];
}

export interface Outcome {
  title: string;
  value: string;
  isTarget: boolean;
}

export interface OutcomeAssessmentResultOptions {
  pillars: OutcomePillar[];
}

export interface PreviewItem {
  label: string;
  value: string;
}

export interface ScaffolderExtras {
  preview: Record<string, PreviewItem>;
  context: Record<string, any>;
}

export type FormDataWithExtras = JsonObject & {
  __extras: ScaffolderExtras;
};

export type ValueMappings = Record<string, ValueMapping[]>;

export interface AeToolkit {
  label: string;
  url: string;
}

export type ScorringKind =
  | 'ladder'
  | 'percent'
  | 'group'
  | 'like-love'
  | 'outcomes'
  | 'preview';

export interface Mandatory {
  tooltip: string;
  fields: string[];
}

export interface PreviewOptions {
  key: string;
  label: string;
  scoring: ScorringKind;
  submitText?: string;
  categories?: QuestionnaireCategory[];
  mandatory?: Mandatory;
  mappings?: ValueMappings;
  pillars?: OutcomePillar[];
  toolkit?: AeToolkit;
}

export function setFieldPreview(
  formData: FormDataWithExtras,
  fieldKey: string,
  data: PreviewItem,
) {
  const extras = formData.__extras ?? {};
  const preview = extras.preview ?? {};

  formData.__extras = {
    ...extras,
    preview: {
      ...preview,
      [fieldKey]: data,
    },
  };
}

const defaultExtra: ScaffolderExtras = {
  context: {},
  preview: {},
};

export function setExtra(
  formData: FormDataWithExtras,
  key: string,
  value: any,
) {
  const extras = formData.__extras ?? defaultExtra;
  formData.__extras = {
    ...extras,
    context: {
      ...extras.context,
      [key]: value,
    },
  };
}

export function getExtra<T>(formData: FormDataWithExtras, key: string): T {
  return formData.__extras?.context[key];
}

export function getPreviewOptions(
  steps: StepCollection,
): PreviewOptions | undefined {
  const maybeWelcome = steps
    .flatMap(s => {
      return Object.values(s.mergedSchema.properties ?? {});
    })
    .find(prop => {
      return prop['ui:field'] === 'WelcomeComponent';
    });
  return maybeWelcome?.['ui:preview'];
}
