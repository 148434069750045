import { groupByMap } from '../utilities';
import { QuestionnaireCategory } from '../../QuestionnaireResults/schema';

export const categoryMap = (categories: QuestionnaireCategory[]) =>
  groupByMap(
    categories,
    c => c.id,
    g => g[0],
  );
export const makeCounter = (categories: QuestionnaireCategory[]) =>
  Object.fromEntries(categories.map(c => [c.id, 0]));
