import { PreviewOptions, ValueMappings } from '../../../extensions';
import {
  QuestionnaireCategory,
  StepCollection,
} from '../../QuestionnaireResults/schema';
import type { JsonObject } from '@backstage/types';
import { groupBy } from 'lodash';
import { categoryMap as createMap } from './scorring-common';
import { definedPredicate } from '../utilities';

const getGroupPercentage = (
  fields: JsonObject,
  mappings: ValueMappings,
  categories: QuestionnaireCategory[],
  defaultCategory: QuestionnaireCategory,
  formData: any,
) => {
  const fieldArray = Object.keys(fields);
  const countedFields = fieldArray
    .map(key => {
      const mappedValues = mappings[key];
      if (!mappedValues) {
        return undefined;
      }
      const value = mappedValues?.find(it => it.value === formData[key]);
      return {
        category: value?.category || defaultCategory.id,
        count: 1,
      };
    })
    .filter(definedPredicate);

  const categoryMap = groupBy(countedFields, f => f.category);
  const distribution = Object.fromEntries(
    Object.entries(categoryMap).map(entry => {
      const [key, values] = entry;

      return [key, values.reduce((acc, item) => acc + item.count, 0)];
    }),
  );
  return categories.map(c => {
    // const total = fieldArray.length;
    const scored = distribution[c.id] ?? 0;
    return {
      id: c.id,
      value: scored,
    };
  });
};

export interface GroupCategoryScore {
  id: string;
  title: string;
  value: number;
}

export interface GroupScoreData {
  title: string;
  categories: GroupCategoryScore[];
}

export interface GroupScoreResult {
  defaultCategory: QuestionnaireCategory;
  data: GroupScoreData[];
}

export function getGroupScore(
  options: PreviewOptions,
  steps: StepCollection,
  formData: Record<string, any>,
): GroupScoreResult {
  const { categories, mappings } = options;
  if (!categories || !mappings) {
    return {
      data: [],
      defaultCategory: {
        id: 'unknown',
        title: 'unknown',
      },
    };
  }
  const map = createMap(categories);
  const defaultCategory = categories.find(c => c.isDefault) ?? categories[0];

  const data = steps
    .filter(s => s.mergedSchema['ui:metric-group'])
    .map(s => {
      const group = s.mergedSchema['ui:metric-group'] as string;
      const props = (s.mergedSchema.properties as JsonObject) ?? {};
      const result = getGroupPercentage(
        props,
        mappings,
        categories,
        defaultCategory,
        formData,
      ).map(g => ({
        id: g.id,
        title: map[g.id]?.title ?? defaultCategory.title,
        value: g.value,
      }));

      const title = (s.mergedSchema.title as string) || group;
      return {
        title,
        categories: result,
      };
    });

  return {
    data,
    defaultCategory,
  };
}
