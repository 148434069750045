import { PreviewOptions } from '../../../extensions';
import { groupBy, uniqBy } from 'lodash';

function increment(category: string, acc: Record<string, number>) {
  return {
    ...acc,
    [category]: (acc[category] ?? 0) + 1,
  };
}

export function getPercentScore(
  previewOptions: PreviewOptions,
  formData: Record<string, any>,
  options?: { mapToDefault: boolean },
) {
  const { mappings, categories } = previewOptions;
  if (!mappings || !categories) {
    return {};
  }
  const defaultCategory = categories.find(c => c.isDefault);
  const { mapToDefault } = options ?? {};

  const defaultCategoryTotals = {
    [defaultCategory?.id ?? '__']: Object.keys(mappings).length,
  };

  const categoryTotals = Object.fromEntries(
    Object.entries(
      groupBy(
        Object.keys(formData).flatMap(key =>
          uniqBy(mappings[key], it => it.category),
        ),
        it => it.category,
      ),
    ).map(([key, elements]) => [key, elements.length]),
  );
  const totals = {
    ...defaultCategoryTotals,
    ...categoryTotals,
  };
  const categoryScore: Record<string, number> = Object.entries(formData).reduce(
    (acc, item) => {
      const [key, value] = item;
      const metric = mappings[key];
      if (!metric) {
        return acc;
      }

      const selectedValue = metric.find(it => it.value === value);

      if (!selectedValue?.category) {
        return mapToDefault && defaultCategory
          ? increment(defaultCategory.id, acc)
          : acc;
      }

      return increment(selectedValue.category, acc);
    },
    {} as Record<string, number>,
  );
  return Object.fromEntries(
    categories.map(c => {
      const total = totals[c.id] ?? -1;
      const scored = categoryScore[c.id] ?? 0;
      return [c.id, (scored / total) * 100];
    }),
  );
}
