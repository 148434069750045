import { makeFieldSchema } from '@backstage/plugin-scaffolder-react';

export interface WelcomeComponentOptions {
  query: string;
  kind: 'labelType' | 'label';
  title: string;
  previewKey: string;
}

export interface ProductMaturityConfig {
  baseUrl: string;
  apiKey: string;
  endpointGuid: string;
}

export const welcomeSchema = makeFieldSchema({
  uiOptions: z => z.any(),
  output: z => z.string(),
});

export interface QuestionField {
  shortQuestionName: string;
  shortAnswerTypeName: string;
  dataType: 'boolean' | 'string';
}

export interface PreviousAnswersDto {
  fields: QuestionField[];
}
