import { groupBy } from 'lodash';

export function groupByMap<T, TResult>(
  source: T[],
  keySelector: (item: T) => string,
  selector: (items: T[]) => TResult,
): Record<string, TResult> {
  const grouping = groupBy(source, keySelector);
  return Object.fromEntries(
    Object.entries(grouping).map(entry => {
      const [key, value] = entry;
      return [key, selector(value)];
    }),
  );
}

export function definedPredicate<T>(candidate: T | undefined): candidate is T {
  return !!candidate;
}
