import React from 'react';
import { ReviewStepProps } from '@backstage/plugin-scaffolder-react';
import {
  ParsedTemplateSchema,
  ReviewState,
} from '@backstage/plugin-scaffolder-react/alpha';
import {
  FormDataWithExtras,
  getPreviewOptions,
  ScorringKind,
} from '../extensions';
import { Button, styled } from '@material-ui/core';
import { QuestionnaireReview } from './QuestionnaireResults/QuestionnaireReview';
import { OutcomesReview } from './OutcomeReview';
import { StepCollection } from './QuestionnaireResults/schema';
import { CustomReview } from './CustomReview.tsx';

const Footer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
});
const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
});

type PreviewKind = 'outcomes' | 'questionnaire' | 'preview' | undefined;
const questionnaires: ScorringKind[] = [
  'like-love',
  'ladder',
  'group',
  'percent',
];

function resolvePreviewStep(steps: ParsedTemplateSchema[]): PreviewKind {
  const options = getPreviewOptions(steps);
  const { scoring } = options ?? {};
  if (questionnaires.includes(scoring as ScorringKind)) {
    return 'questionnaire';
  }
  return scoring as PreviewKind;
}

export function Review(props: ReviewStepProps) {
  const formData = props.formData as FormDataWithExtras;
  const steps = props.steps as StepCollection;
  const { handleCreate, handleBack } = props;
  const previewOptions = getPreviewOptions(steps);
  const content = () => {
    const reviewKind = resolvePreviewStep(steps);

    switch (reviewKind) {
      case 'outcomes': {
        return <OutcomesReview formData={formData} steps={steps} />;
      }
      case 'questionnaire': {
        return <QuestionnaireReview formData={formData} steps={steps} />;
      }
      case 'preview': {
        return <CustomReview formData={formData} steps={steps} />;
      }
      default: {
        return (
          <ReviewState
            schemas={props.steps as ParsedTemplateSchema[]}
            formState={props.formData}
          />
        );
      }
    }
  };

  return (
    <Root>
      {content()}
      <Footer>
        <Button onClick={handleBack}> Back </Button>
        <Button onClick={handleCreate} variant="contained" color="primary">
          {previewOptions?.submitText || 'Create'}
        </Button>
      </Footer>
    </Root>
  );
}
