import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
  CatalogTableColumnsFunc,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage, searchPlugin } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  LightBox,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import {
  InsightsPage,
  insightsPlugin,
  InsightsSurveyLoader,
} from '@spotify/backstage-plugin-insights';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { RBACRoot } from '@spotify/backstage-plugin-rbac';

import * as plugins from './plugins';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { AccTechradarPage } from '@internal/backstage-plugin-acc-techradar';
import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';
import { CustomizableHomePage } from './components/home/CustomizableHomePage';
import {
  CustomTabConfig,
  CustomTechDocsHome,
} from './components/techdocs/CustomTechDocsHome';
import { IntercomMessenger } from '@internal/backstage-plugin-intercom';
import { SkillExchangePage } from '@spotify/backstage-plugin-skill-exchange';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';

import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';

import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { Integrations } from './components/integrations/integrations';
import {
  AccDocsEditorPage,
  EditTechDocsSetting,
} from '@internal/backstage-plugin-acc-docs-editor';

import { CopilotIndexPage } from '@backstage-community/plugin-copilot';

import { NotificationsPage } from '@backstage/plugin-notifications';

import { SignalsDisplay } from '@backstage/plugin-signals';
import { nexusThemes } from './theme/nexusTheme';
import { DefaultRenderNode } from './components/catalog/nodes/DefaultRenderNode';
import { PlayHtPage } from '@internal/backstage-plugin-play-ht';
import { ae360ScaffolderExtensions, Review } from '@internal/acc-react-shared';
import { styled } from '@material-ui/core/styles';

const ScaffolderRoot = styled('div')({
  '& article': {
    padding: '35px',
  },
});

const techDocsTabsConfig: CustomTabConfig[] = [
  {
    slug: 'recommended',
    label: 'Recommended Documentation',
    panels: [
      {
        title: 'Backstage',
        description: 'Documentation about how to get the best out of backstage',
        panelType: 'DocsCardGrid',
        filterPredicate: entity =>
          entity?.metadata?.tags?.includes('recommended') ?? false,
      },
    ],
  },
];

const myColumnsFunc: CatalogTableColumnsFunc = entityListContext => {
  if (entityListContext.filters.kind?.value === 'system') {
    return [
      CatalogTable.columns.createNameColumn(),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createLabelColumn(
        'accessacloud.com/reporting-code',
        { title: 'Reporting Code' },
      ),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  return CatalogTable.defaultColumnsFunc(entityListContext);
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  featureFlags: [
    {
      pluginId: 'azure-home-prs',
      name: 'ado-pull-requests',
      description:
        'Enables the homepage plugin to showcase your Open and Assigned PRs from Azure DevOps',
    },
    {
      pluginId: '',
      name: 'github-pull-requests',
      description:
        'Enables the homepage plugin to showcase your Open and Assigned PRs from GitHub',
    },
    {
      pluginId: '',
      name: 'copilot-enabled',
      description:
        'Enables / Disables the Copilot UI. Note the backend  can only be configured via teh appconfig file',
    },
  ],
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          providers={[
            {
              id: 'microsoft-auth-provider',
              title: 'Microsoft',
              message: 'Sign in using Access AD',
              apiRef: microsoftAuthApiRef,
            },
          ]}
        />
      );
    },
  },

  themes: nexusThemes,

  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(insightsPlugin.externalRoutes, {
      searchPageRouteRef: searchPlugin.routes.root,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <FeatureFlagged with="customizable-home-page-preview">
      <Route path="/home" element={<HomepageCompositionRoot />}>
        <CustomizableHomePage />
      </Route>
    </FeatureFlagged>
    <FeatureFlagged without="customizable-home-page-preview">
      <Route path="/home" element={<HomepageCompositionRoot />}>
        <HomePage />
      </Route>
    </FeatureFlagged>
    <Route
      path="/catalog"
      element={<CatalogIndexPage columns={myColumnsFunc} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomTechDocsHome tabsConfig={techDocsTabsConfig} />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <EditTechDocsSetting />
        <TextSize />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderRoot>
          <ScaffolderPage
            components={{
              ReviewStepComponent: Review,
            }}
            groups={[
              {
                title: 'Evo',
                filter: entity =>
                  entity?.metadata?.tags?.includes('evo') ?? false,
              },
              {
                title: 'Documentation',
                filter: entity =>
                  (entity?.metadata?.tags?.includes('mkdocs') ?? false) ||
                  entity?.spec.type === 'documentation',
              },
              {
                title: 'Access Engineering 360',
                filter: entity =>
                  entity?.metadata?.tags?.includes('ae360') ?? false,
              },
            ]}
          />
        </ScaffolderRoot>
      }
    >
      <ScaffolderFieldExtensions>
        {ae360ScaffolderExtensions()}
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={<CatalogGraphPage renderNode={DefaultRenderNode} />}
    />
    <Route
      path="/soundcheck"
      element={<SoundcheckRoutingPage title="Soundcheck" />}
    />
    <Route path="/insights" element={<InsightsPage />} />
    <Route path="/rbac" element={<RBACRoot />} />
    <Route
      path="/copilot"
      element={
        <FeatureFlagged with="copilot-enabled">
          <CopilotIndexPage />
        </FeatureFlagged>
      }
    />

    <Route path="/notifications" element={<NotificationsPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/acc-techradar" element={<AccTechradarPage />} />
    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route
      path="/skill-exchange"
      element={<SkillExchangePage enableEmbeds />}
    />
    <Route
      path="/docs-editor/:namespace/:kind/:name"
      element={<AccDocsEditorPage />}
    />
    <Route path="/play-ht" element={<PlayHtPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <Integrations />
    <AlertDisplay />
    <OAuthRequestDialog />
    <SignalsDisplay />
    <AppRouter>
      <VisitListener />
      <IntercomMessenger />
      <InsightsSurveyLoader />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
