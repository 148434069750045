import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  CatalogIcon,
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import { useApp } from '@backstage/core-plugin-api';
import { InsightsSidebarItem } from '@spotify/backstage-plugin-insights';
import { RBACSidebarItem } from '@spotify/backstage-plugin-rbac';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SchoolIcon from '@material-ui/icons/School';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import BuildIcon from '@material-ui/icons/Build';

import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { CopilotSidebar } from '@backstage-community/plugin-copilot';

import { NotificationsSidebarItem } from '@backstage/plugin-notifications';
import LogoFullSideBar from './LogoFullSideBar';

import { MySquadsSidebarItem } from '../home/Home_components/MySquadsSideBarItem';
import { FeatureFlagged } from '@backstage/core-app-api';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFullSideBar /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarItem
        icon={HomeIcon}
        data-intercom-target="sidebar-home"
        to="home"
        text="Home"
      />
      <NotificationsSidebarItem
        webNotificationsEnabled
        snackbarEnabled={false}
      />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <span data-intercom-target="sidebar-my-squad">
          <MySquadsSidebarItem
            singularTitle="My Squad"
            pluralTitle="My Squads"
            icon={GroupIcon}
            filter={{ 'spec.type': 'squad' }}
          />
        </span>
        {/* Global nav, not org-specific */}
        <SidebarItem
          icon={CatalogIcon}
          data-intercom-target="sidebar-catalog"
          to="catalog"
          text="Catalog"
        >
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=domain"
              icon={useApp().getSystemIcon('kind:domain')}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <SidebarSubmenuItem
              title="APIs"
              to="catalog?filters[kind]=api&filters"
              icon={useApp().getSystemIcon('kind:api')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource&filters"
              icon={useApp().getSystemIcon('kind:resource')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Groups"
              to="catalog?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
            />
          </SidebarSubmenu>
        </SidebarItem>

        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem
          icon={LibraryBooks}
          to="docs"
          data-intercom-target="sidebar-documentation"
          text="Docs"
        />
        <SidebarItem
          icon={CreateComponentIcon}
          to="create"
          data-intercom-target="sidebar-scaffolder"
          text="Create..."
        />
        <SidebarItem
          icon={ImportExportIcon}
          to="catalog-import"
          data-intercom-target="sidebar-import"
          text="Import Repo"
        />
        <FeatureFlagged with="copilot-enabled">
          <CopilotSidebar />
        </FeatureFlagged>
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem
          icon={MapIcon}
          to="acc-techradar"
          text="Tech Radar"
          data-intercom-target="sidebar-tech-radar"
        />
        <SidebarItem icon={LiveHelpIcon} to="qeta" text="Q&A" />

        <SidebarItem icon={DoneAllIcon} to="soundcheck" text="Soundcheck" />

        <SidebarScrollWrapper>
          <SidebarItem
            icon={SchoolIcon}
            to="skill-exchange"
            text="Skill Exchange"
          />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />

      <Shortcuts allowExternalLinks />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
        <RequirePermission
          permission={devToolsAdministerPermission}
          errorPage={<></>}
        >
          <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
        </RequirePermission>
        <RBACSidebarItem />
        <InsightsSidebarItem />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
