import { PreviewOptions } from '../../../extensions';
import { orderBy } from 'lodash';
import { categoryMap, makeCounter } from './scorring-common';

export function getLikedLoveScore(
  options: PreviewOptions,
  formData: Record<string, any>,
) {
  const { categories, mappings } = options;
  if (!categories || !mappings) {
    return {};
  }
  const defaultCategory = categories[0];
  const map = categoryMap(categories);
  const mappingCollection = Object.entries(mappings);

  const counter = makeCounter(categories);
  const totals = { ...counter };

  mappingCollection.forEach(mapping => {
    const [key, values] = mapping;
    const foundValue = values.find(v => v.value === formData[key]);
    if (!foundValue) {
      counter[defaultCategory.id] += 1;
      return;
    }
    const currentCategory = map[foundValue.category];
    counter[currentCategory.id] += 1;
    categories
      .filter(
        c =>
          c.level! > defaultCategory.level! &&
          c.level! < currentCategory.level!,
      )
      .forEach(c => {
        counter[c.id] += 1;
      });
  });

  mappingCollection.forEach(mapping => {
    const [, values] = mapping;
    const highest = orderBy(values, v => map[v.category].level, ['desc'])[0];
    categories
      .filter(c => c.level! <= map[highest.category].level!)
      .forEach(c => {
        totals[c.id] += 1;
      });
  });
  return Object.fromEntries(
    categories.map(category => {
      return [category.id, (counter[category.id] / totals[category.id]) * 100];
    }),
  );
}
