import { Divider, styled, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { DataItem, SegmentData } from './schema';
import { SegmentBarChartLegend } from './SegmentBarChartLegend';
import { Segment } from './Segment';

export interface SegmentedBarChartProps {
  data: DataItem[];
  title?: string;
  height?: number;
  barHeight?: number;
}

const segmentTitle = (segment: SegmentData) =>
  `${
    segment.category
  }: ${segment.value.toLocaleString()} (${segment.percentage.toFixed(1)}%)`;

type SegmentBarProps = {
  barHeight: number;
};

const SegmentBar = styled(Box)<Theme, SegmentBarProps>(
  ({ theme, barHeight }) => ({
    position: 'relative',
    height: barHeight,
    width: '100%',
    borderRadius: 1,
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(3),
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
  }),
);

export function SegmentedBarChart(props: SegmentedBarChartProps) {
  const { data = [], title = 'Title', barHeight = 50 } = props;

  const total = data.reduce((sum, item) => sum + item.value, 0);
  let cumulativePercentage = 0;
  const segments = data.map(item => {
    const percentage = (item.value / total) * 100;
    const start = cumulativePercentage;
    cumulativePercentage += percentage;

    return {
      ...item,
      percentage,
      start,
      width: percentage,
    };
  });

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="caption" gutterBottom>
        Total: {total}
      </Typography>
      <SegmentBar barHeight={barHeight}>
        {segments
          .filter(s => s.value > 0)
          .map((segment, index) => (
            <Tooltip
              key={index}
              title={segmentTitle(segment)}
              arrow
              placement="top"
            >
              <Segment
                segmentCount={segments.length}
                index={index}
                segment={segment}
              />
            </Tooltip>
          ))}
      </SegmentBar>

      <SegmentBarChartLegend segments={segments} />
      <Divider />
    </Box>
  );
}
