import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { styled, Theme } from '@material-ui/core';
import { DataItem } from './schema';

export interface SegmentBarChartLegendProps {
  segments: any[];
}

const Root = styled(Box)(theme => ({
  marginTop: theme.theme.spacing(3),
  display: 'flex',
  flexWrap: 'wrap',
}));

const LegendItem = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: props.theme.spacing(3),
  marginBottom: props.theme.spacing(2),
}));

const LegendIcon = styled(Box)<Theme, { segment: DataItem }>(props => ({
  width: 16,
  height: 16,
  borderRadius: 0.5,
  backgroundColor: props.segment.color,
  marginRight: props.theme.spacing(1),
  border: `1px solid ${props.theme.palette.divider}`,
}));

export function SegmentBarChartLegend(props: SegmentBarChartLegendProps) {
  const { segments } = props;
  return (
    <Root>
      {segments.map((segment, index) => (
        <LegendItem key={index}>
          <LegendIcon segment={segment} />
          <Typography variant="body2">
            {segment.category}: {segment.value.toLocaleString()} (
            {segment.percentage.toFixed(1)}%)
          </Typography>
        </LegendItem>
      ))}
    </Root>
  );
}
