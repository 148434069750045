import React, { Fragment } from 'react';
import { alpha, styled, Theme, Typography } from '@material-ui/core';
import { CategoryQuestionCollection } from '../scaffolder/Review/utils/question-grouping';
import Tooltip from '@material-ui/core/Tooltip';
import { Mandatory } from '../scaffolder/extensions.ts';

export interface TreePropsViewerProps {
  groups: CategoryQuestionCollection[];
  mandatory?: Mandatory;
}

const Root = styled('div')({
  display: 'grid',
  width: '100%',
  rowGap: '0.5rem',
  gridTemplateColumns: '1fr max-content',
});

const Title = styled(Typography)(() => ({
  gridColumn: '1/ span 2',
  padding: '0.5rem',
  borderBottom: `1px solid #757575;`,
}));

const ValueRoot = styled('div')({
  padding: '0.5rem',
});
const Key = styled('div')({
  padding: '0.5rem',
  marginLeft: '1rem',
  fontWeight: 'bold',
});

const GroupContainer = styled('div')({
  display: 'contents',
});

type PropsRowProps = {
  isError?: boolean;
};
const PropsRow = styled('div')<Theme, PropsRowProps>(props => ({
  display: 'contents',

  '&:first-of-type': {
    '& > div': {
      backgroundColor: props.theme.palette.background.default,
    },
  },

  '&:nth-of-type(odd)': {
    '& > div': {
      backgroundColor: props.theme.palette.background.default,
    },
  },
  '& > div': {
    backgroundColor: props.isError
      ? `${alpha(props.theme.palette.error.main, 0.2)}!important`
      : undefined,
  },
}));

const EmptyState = styled('div')(props => ({
  padding: '0.5rem',
  gridColumn: '1/ span 2',
  display: 'grid',
  placeContent: 'center',
  backgroundColor: props.theme.palette.background.default,
}));

const renderValue = (value: any) => {
  if (typeof value === 'boolean') {
    return <Fragment>{value ? '✅' : '❌'}</Fragment>;
  }

  if (!value || value === '') {
    return '(empty)';
  }

  if (Array.isArray(value)) {
    return `[${value.join(', ')}]`;
  }

  return value?.toString();
};

export function Value({ value }: { value: any }) {
  return <ValueRoot> {renderValue(value)}</ValueRoot>;
}

export function TreePropsViewer(props: TreePropsViewerProps) {
  const { groups, mandatory } = props;
  const { fields = [], tooltip = '' } = mandatory ?? {};
  return (
    <Root>
      {groups.map((group, idx) => {
        const entries = group.questions;
        return (
          <GroupContainer key={idx}>
            <Title variant="h6">{group.title}</Title>
            {entries.length === 0 && <EmptyState>No Questions </EmptyState>}
            {entries.map((q, qIdx) => {
              const { question, value, id } = q;
              const isError =
                fields.includes(id) && (group.isNegative || !value);
              return (
                <PropsRow key={qIdx} isError={isError}>
                  <Key>
                    {isError ? (
                      <Tooltip title={tooltip} arrow placement="right-end">
                        <span>{question}</span>
                      </Tooltip>
                    ) : (
                      question
                    )}
                  </Key>

                  <Value value={value} />
                </PropsRow>
              );
            })}
          </GroupContainer>
        );
      })}
    </Root>
  );
}
