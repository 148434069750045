import { PreviewOptions } from '../../../extensions';
import { getLikedLoveScore } from './like-love.score';
import { getPercentScore } from './percentage.score';
import { getLadderScore } from './ladder.score';
import { StepCollection } from '../../QuestionnaireResults/schema';
import { getGroupScore, GroupScoreResult } from './group.score';

export interface CategoryScore {
  title: string;
  isNegative: boolean;
  score: number;
}

export interface GroupPercentScore {
  percentScore: CategoryScore[];
  groupScore: GroupScoreResult;
}

export function isGroupPercentScore(
  candidate: CategoryScore[] | GroupPercentScore,
): candidate is GroupPercentScore {
  const maybeGroup = candidate as GroupPercentScore;
  return !!maybeGroup.percentScore && !!maybeGroup.groupScore;
}

export function getScore(
  options: PreviewOptions,
  steps: StepCollection,
  formData: Record<string, any>,
): CategoryScore[] | GroupPercentScore {
  const toCategoryScore = (
    rawScore: Record<string, number>,
    categories = options.categories,
  ) => {
    if (!categories) {
      return [];
    }
    return categories.map(c => {
      return {
        title: c.title,
        isNegative: c.isNegative ?? false,
        score: rawScore[c.id] as number,
      };
    });
  };

  switch (options.scoring) {
    case 'like-love':
      return toCategoryScore(getLikedLoveScore(options, formData));
    case 'ladder':
      return toCategoryScore(getLadderScore(options, formData));
    case 'group': {
      const groupScore = getGroupScore(options, steps, formData);
      const percentScore = toCategoryScore(
        getLikedLoveScore(options, formData),
      );

      return {
        groupScore,
        percentScore,
      };
    }
    default:
      return toCategoryScore(getPercentScore(options, formData));
  }
}
