import { styled, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { SegmentData } from './schema';

type SegmentProps = {
  segment: SegmentData;
  index: number;
  segmentCount: number;
};

export const Segment = styled(Box)<Theme, SegmentProps>(
  ({ theme, segment, index, segmentCount }) => ({
    height: '100%',
    width: `${segment.width}%`,
    backgroundColor: segment.color,
    border: index > 0 ? `1px solid ${theme.palette.background.paper}` : 'none',
    borderRight:
      index < segmentCount - 1
        ? `1px solid ${theme.palette.background.paper}`
        : 'none',
    position: 'relative', // Changed from absolute
    boxSizing: 'border-box',
    minWidth: '4px', // Ensure very small segments are visible
    '&:hover': {
      opacity: 0.85,
      transform: 'scaleY(1.05)',
      zIndex: 1,
    },
  }),
);
