import React, { Fragment } from 'react';
import { Box, darken } from '@material-ui/core';

import {
  GroupCategoryScore,
  GroupScoreResult,
} from '../utils/scoring/group.score';
import { DataItem } from '../../../components/SegmentBarChart/schema';
import { SegmentedBarChart } from '../../../components/SegmentBarChart/SegmentedBarChart';

export interface GroupScoreProps {
  score: GroupScoreResult;
}

const baseColor = '#8ac48c';
const emptycolor = '#776783fc';

export function GroupScore(props: GroupScoreProps) {
  const { score } = props;
  const { data, defaultCategory } = score;

  const mapGraphData = (categ: GroupCategoryScore, idx: number) => ({
    category: categ.title,
    value: categ.value,
    color:
      categ.id === defaultCategory.id
        ? emptycolor
        : darken(baseColor, idx * 0.2),
  });

  return (
    <Box
      display="grid"
      width="100%"
      gridGap="0.5rem"
      gridTemplateColumns="repeat(auto-fit, minmax(300px,1fr))"
    >
      {data.map(d => {
        const defaultCategoryData =
          d.categories.find(c => c.id === defaultCategory.id) ??
          d.categories[0];
        const segmentData: DataItem[] = [
          ...d.categories
            .filter(c => c !== defaultCategoryData)
            .map((categ, idx) => mapGraphData(categ, idx)),
          mapGraphData(defaultCategoryData, 0),
        ];
        return (
          <Fragment>
            <SegmentedBarChart data={segmentData} title={d.title} />
          </Fragment>
        );
      })}
    </Box>
  );
}
