import React, { Fragment } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { ScoreTable } from './Score/ScoreTable';
import {
  FormDataWithExtras,
  getExtra,
  getPreviewOptions,
} from '../../extensions';
import { getScore, isGroupPercentScore } from '../utils/scoring/scoring';
import { GroupedQuestions } from '../GroupedFields';
import { Ae360Logo } from '../../Ae360Logo';
import { GroupScore } from './GroupScore';
import { StepCollection } from './schema';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export interface QuestionnaireReviewProps {
  formData: FormDataWithExtras;
  steps: StepCollection;
}

export function QuestionnaireReview(props: QuestionnaireReviewProps) {
  const { formData, steps } = props;
  const title = getExtra<string>(formData, 'product_name');
  const code = getExtra<string>(formData, 'product_code');
  const options = getPreviewOptions(steps);

  if (!options) {
    return <div> Invalid schema</div>;
  }
  const { toolkit } = options;
  const rawScore = getScore(options, steps as StepCollection, formData);
  const groupScore = isGroupPercentScore(rawScore) ? rawScore : undefined;
  const percentScore = isGroupPercentScore(rawScore)
    ? rawScore.percentScore
    : rawScore;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ gap: '2rem' }}
    >
      <Ae360Logo />

      {title && (
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          {title} - {code}
        </Typography>
      )}

      <ScoreTable table={percentScore} />
      {toolkit && (
        <a target="_blank" href={toolkit.url}>
          <Box display="flex" style={{ gap: '0.5rem' }} alignItems="center">
            <Typography variant="h6">{toolkit.label || 'Toolkit'}</Typography>
            <OpenInNewIcon />
          </Box>
        </a>
      )}
      {groupScore && (
        <Fragment>
          <Divider style={{ alignSelf: 'flex-start', width: '100%' }} />
          <GroupScore score={groupScore.groupScore} />
        </Fragment>
      )}

      <GroupedQuestions
        steps={steps as any}
        formData={formData}
        options={options}
        mapToDefault={
          isGroupPercentScore(rawScore) || options.scoring === 'like-love'
        }
      />
      <Typography variant="body2">
        This data will be submitted and reported into Power BI for your Division{' '}
      </Typography>
    </Box>
  );
}
