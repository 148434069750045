import React from 'react';
import { WelcomeComponentExtension } from './WelcomeComponent/WelcomeComponent';
import { Ae360LogoExtension } from './Ae360Logo';

export function ae360ScaffolderExtensions() {
  return (
    <>
      <WelcomeComponentExtension />
      <Ae360LogoExtension />
    </>
  );
}
