import { getExtra, PreviewOptions } from '../../extensions';
import { StepCollection, ValueMapping } from '../QuestionnaireResults/schema';
import type { JsonObject } from '@backstage/types';
import { findSchemaForKey } from './schema-utils';
import { Draft07 as JSONSchema } from 'json-schema-library';
import { groupBy } from 'lodash';
import { ParsedTemplateSchema } from '@backstage/plugin-scaffolder-react/dist/alpha';
import { JsonSchema as DefinitionSchema } from 'json-schema-library/dist/lib/types';
import { definedPredicate } from './utilities.ts';

export interface CategoryQuestion {
  id: string;
  question: string;
  value: any;
}

export interface CategoryQuestionCollection {
  title: string;
  isNegative: boolean;
  questions: CategoryQuestion[];
}

function getPreviewValue(
  key: string,
  schema: ParsedTemplateSchema,
  definitionSchema: DefinitionSchema | undefined,
  formData: JsonObject,
) {
  const rawValue = formData[key];

  const previewOptions: PreviewOptions | undefined =
    schema.uiSchema['ui:preview'] ?? {};

  if (previewOptions?.key) {
    return getExtra(formData as any, previewOptions.key) as string;
  }

  if (definitionSchema?.enum && definitionSchema?.enumNames) {
    return (
      definitionSchema.enumNames[definitionSchema.enum.indexOf(rawValue)] ||
      rawValue
    );
  }

  return rawValue;
}

export function getQuestionsByCategory(
  previewOptions: PreviewOptions,
  steps: StepCollection,
  formData: JsonObject,
  options?: { mapToDefault: boolean },
): CategoryQuestionCollection[] {
  const { categories, mappings } = previewOptions;

  if (!categories || !mappings) {
    return [];
  }

  const { mapToDefault } = options ?? {};
  const defaultCategory = categories.find(c => c.isDefault) ?? categories[0];

  const findMapping = (metric: ValueMapping[], value: any) => {
    const defaultValue: ValueMapping = mapToDefault
      ? {
          ...metric[0],
          category: defaultCategory.id,
        }
      : metric[0];
    return metric.find(m => m.value === value) ?? defaultValue;
  };

  const resolveValue = (key: string, selectedValue: ValueMapping) => {
    const schema = findSchemaForKey(key, steps, formData);

    if (!schema) {
      return null;
    }
    const localPreviewOptions: PreviewOptions | undefined =
      schema.uiSchema['ui:preview'] ?? {};

    const parsedSchema = new JSONSchema(schema.mergedSchema);
    const definitionInSchema = parsedSchema.getSchema({
      pointer: `#/${key}`,
      data: formData,
    });

    const title =
      localPreviewOptions?.label ||
      (definitionInSchema?.title as string) ||
      'Unknown';
    const previewValue = getPreviewValue(
      key,
      schema,
      definitionInSchema,
      formData,
    );

    return {
      category: selectedValue.category,
      preview: {
        id: key,
        question: title,
        value: previewValue,
      } as CategoryQuestion,
    };
  };

  const questionData = Object.entries(mappings).map(([k, metric]) => {
    const value = formData[k];

    const selectedValue = findMapping(metric, value);
    return resolveValue(k, selectedValue);
  });

  const grouping = groupBy(questionData, d => d?.category);

  return categories.map(it => {
    const entries = grouping[it.id] ?? [];
    return {
      title: it.title,
      isNegative: it.isNegative ?? false,
      questions: entries.map(e => e?.preview).filter(definedPredicate),
    };
  });
}
