import {
  PreviousAnswersDto,
  ProductMaturityConfig,
  QuestionField,
  WelcomeComponentOptions,
} from './schema.ts';

export function trimSlash(base: string) {
  return base.replace(/\/$/, '');
}

const toBoolean = (v: string) => v?.endsWith('yes') ?? false;

function parseValues(field: QuestionField) {
  return field.dataType === 'boolean'
    ? toBoolean(field.shortAnswerTypeName)
    : field.shortAnswerTypeName;
}

export function parseResponse(
  answers: PreviousAnswersDto,
): Record<string, any> {
  const entries = answers.fields.map(field => {
    const key = field.shortQuestionName;
    const value = parseValues(field);

    return [key, value];
  });

  return Object.fromEntries(entries);
}

type Deps = {
  config: ProductMaturityConfig;
  options: Pick<WelcomeComponentOptions, 'kind' | 'query'>;
};

export async function fetchPreviousAnswer(product: string, deps: Deps) {
  const { config, options } = deps;
  const { baseUrl, apiKey, endpointGuid } = config;
  const segment = options.kind === 'label' ? 'labels' : 'labelTypes';
  const headers = new Headers();
  headers.set('Authorization', apiKey);
  try {
    const endpoint = `${trimSlash(
      baseUrl,
    )}/${endpointGuid}/targets/${product}/${segment}/${
      options.query
    }/recentAnswers`;
    const result = await fetch(endpoint, {
      headers,
    });
    if (result.ok) {
      const rawResult: PreviousAnswersDto = await result.json();
      return parseResponse(rawResult);
    }
    return {};
  } catch (error) {
    return {};
  }
}
