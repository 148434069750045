import React from 'react';
import { StructuredMetadataTable } from '@backstage/core-components';
import { ReviewStepProps } from '@backstage/plugin-scaffolder-react';
import { findSchemaForKey, isJsonObject } from './utils/schema-utils';
import { Draft07 as JSONSchema } from 'json-schema-library';
import type { JsonObject, JsonValue } from '@backstage/types';
import { ParsedTemplateSchema } from '@backstage/plugin-scaffolder-react/alpha';
import { FormDataWithExtras } from '../extensions';
import { Box } from '@material-ui/core';
import { Ae360Logo } from '../Ae360Logo';

function processSchema(
  key: string,
  value: JsonValue | undefined,
  schema: ParsedTemplateSchema,
  formState: JsonObject,
): [string, JsonValue | undefined][] {
  const parsedSchema = new JSONSchema(schema.mergedSchema);
  const definitionInSchema = parsedSchema.getSchema({
    pointer: `#/${key}`,
    data: formState,
  });

  const name =
    definitionInSchema?.['ui:backstage']?.review?.name ??
    definitionInSchema?.title ??
    key;

  if (definitionInSchema) {
    const backstageReviewOptions = definitionInSchema['ui:backstage']?.review;
    if (backstageReviewOptions) {
      if (backstageReviewOptions.mask) {
        return [[name, backstageReviewOptions.mask]];
      }
      if (backstageReviewOptions.show === false) {
        return [];
      }
    }

    if (
      definitionInSchema['ui:widget'] === 'password' ||
      definitionInSchema['ui:field']?.toLocaleLowerCase('en-us') === 'secret'
    ) {
      return [[name, '******']];
    }

    if (definitionInSchema.enum && definitionInSchema.enumNames) {
      return [
        [
          name,
          definitionInSchema.enumNames[
            definitionInSchema.enum.indexOf(value)
          ] || value,
        ],
      ];
    }

    if (backstageReviewOptions?.explode !== false && isJsonObject(value)) {
      // Recurse nested objects
      return Object.entries(value).flatMap(([nestedKey, nestedValue]) =>
        processSchema(`${key}/${nestedKey}`, nestedValue, schema, formState),
      );
    }
  }

  return [[name, value]];
}

function processEntries(
  formData: FormDataWithExtras,
  steps: unknown,
): [string, JsonObject][] {
  const entries = Object.entries(formData).filter(
    ([key]) => key !== '__extras',
  ) as [string, JsonObject][];

  const schemaEntries = Object.fromEntries(
    entries
      .filter(([key]) => !formData.__extras.preview[key])
      .map(([key, value]) => {
        const schema = findSchemaForKey(key, steps as any, formData);
        return schema ? [key, processSchema(key, value, schema, formData)] : [];
      })
      .filter(prop => prop.length > 0),
  );

  return entries.flatMap(([key, value]) => {
    const schemaEntry = schemaEntries[key];
    if (schemaEntry) {
      return schemaEntry;
    }
    const previewExtra = formData.__extras.preview[key];
    if (previewExtra) {
      return [[previewExtra.label, previewExtra.value]];
    }
    return [[key, value]];
  });
}

export interface CustomReviewProps {
  formData: FormDataWithExtras;
  steps: ReviewStepProps['steps'];
}

export function CustomReview(props: CustomReviewProps) {
  const { steps, formData } = props;

  const newEntries = processEntries(formData as FormDataWithExtras, steps);
  const reviewData = Object.fromEntries(newEntries);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ gap: '2rem' }}
    >
      <Ae360Logo />
      <StructuredMetadataTable metadata={reviewData} />
    </Box>
  );
}
