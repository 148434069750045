import React from 'react';
import { styled, Typography, useTheme } from '@material-ui/core';
import {
  Background,
  Donut,
  Outline,
  ScoreContainer,
  ScoreFooter,
  ScoreSegment,
} from './Parts';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export interface ScoreCardProps {
  score: number;
  radius: number;
  isNegative: boolean;
}

const ScoreCard = ({ score, radius, isNegative }: ScoreCardProps) => {
  const percentage = score;
  const circumference = 2 * Math.PI * radius;
  const dashOffset = circumference - (circumference * percentage) / 100;
  const theme = useTheme();
  const size = radius + 10;
  const viewBox = `0 0 ${2 * size} ${2 * size}`;
  return (
    <Root>
      <ScoreContainer>
        <Donut width={2 * size} height={2 * size} viewBox={viewBox}>
          <Background cx={size} cy={size} r={radius - 5} />
          <Outline
            cx={size}
            cy={size}
            r={radius}
            color={isNegative ? theme.palette.error.main : undefined}
            strokeDasharray={circumference}
            strokeDashoffset={0}
          />
          <ScoreSegment
            cx={size}
            cy={size}
            r={radius}
            color={isNegative ? theme.palette.error.main : undefined}
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
          />
        </Donut>

        <ScoreFooter>
          <Typography variant="body2"> {Math.round(score)}% </Typography>
        </ScoreFooter>
      </ScoreContainer>
    </Root>
  );
};

export default ScoreCard;
