import { alpha, darken, styled, Theme } from '@material-ui/core';

export const ScoreContainer = styled('div')({
  position: 'relative',
  width: 120,
  height: 120,
});

export const Donut = styled('svg')({
  transform: 'rotate(-90deg)',
});

export const Background = styled('circle')<Theme, PropsWithColor>(props => ({
  fill: darken(props.color ?? props.theme.palette.background.paper, 0.1),
}));
type PropsWithColor = {
  color?: string;
  theme: Theme;
};
export const Outline = styled('circle')<Theme, PropsWithColor>(props => ({
  stroke: alpha(props.color ?? props.theme.palette.success.main, 0.2),
  fill: 'transparent',
  strokeWidth: 10,
  strokeLinecap: 'round',
  transition: 'stroke-dashoffset 1s ease-in-out',
}));

export const ScoreSegment = styled('circle')<Theme, PropsWithColor>(props => ({
  stroke: props.color ?? props.theme.palette.success.main,
  fill: 'transparent',
  strokeWidth: 10,
  strokeLinecap: 'round',
  transition: 'stroke-dashoffset 1s ease-in-out',
}));

export const ScoreFooter = styled('div')(_ => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: ' translate(-50%, -50%)',
  display: ' flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
