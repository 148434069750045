import React from 'react';
import { FormDataWithExtras, PreviewOptions } from '../extensions';

import { StepCollection } from './QuestionnaireResults/schema';
import { TreePropsViewer } from '../../components/TreePropsViewer';
import { getQuestionsByCategory } from './utils/question-grouping';

export interface GroupedFieldsProps {
  formData: FormDataWithExtras;
  steps: StepCollection;
  options: PreviewOptions;
  mapToDefault?: boolean;
}

export function GroupedQuestions(props: GroupedFieldsProps) {
  const { formData, steps, options, mapToDefault = false } = props;
  const { mandatory } = options;
  const groups = getQuestionsByCategory(options, steps, formData, {
    mapToDefault,
  });

  return <TreePropsViewer groups={groups} mandatory={mandatory} />;
}
