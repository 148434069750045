import Box from '@material-ui/core/Box';
import ScoreCard from './Score';
import React from 'react';
import { Typography } from '@material-ui/core';
import { CategoryScore } from '../../utils/scoring/scoring';

export interface ScoreTableProps {
  table: CategoryScore[];
}

export function ScoreTable(props: ScoreTableProps) {
  const { table } = props;
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${table.length}, minmax(300px, 1fr))`}
    >
      {table.map((score, idx) => {
        return (
          <Box display="flex" flexDirection="column" key={idx}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {score.title}
            </Typography>
            <ScoreCard
              score={score.score}
              radius={50}
              isNegative={score.isNegative}
            />
          </Box>
        );
      })}
    </Box>
  );
}
