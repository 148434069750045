import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import React, { useEffect } from 'react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { configApiRef } from '@backstage/core-plugin-api';
import { EntityPicker } from './ProductPicker';
import { Box, Typography } from '@material-ui/core';
import {
  ProductMaturityConfig,
  WelcomeComponentOptions,
  welcomeSchema,
} from './schema';
import { Entity } from '@backstage/catalog-model';
import {
  FormDataWithExtras,
  PreviewOptions,
  setExtra,
  setFieldPreview,
} from '../extensions';
import { Ae360Logo } from '../Ae360Logo';
import { fetchPreviousAnswer } from './previous-answers-service.ts';

export function getValue(path: string[], context: any) {
  return path.reduce((r, segment) => {
    return r?.[segment];
  }, context);
}

const path = ['metadata', 'labels', 'accessacloud.com/reporting-code'];

const WelcomeComponent = (props: FieldExtensionComponentProps<any>) => {
  const { onChange, uiSchema } = props;
  const uiOptions: WelcomeComponentOptions = uiSchema?.['ui:options'] as any;
  const previewOptions: PreviewOptions | undefined = uiSchema['ui:preview'];
  const formDataWithExtras = props.formContext.formData as FormDataWithExtras;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(undefined), []);

  const onSelect = (newValue: Entity | null) => {
    if (!newValue) {
      onChange(undefined);
      return;
    }

    const value = getValue(path, newValue);
    const label = newValue.metadata.title || newValue.metadata.name;

    if (previewOptions) {
      setFieldPreview(formDataWithExtras, previewOptions.key, {
        label: previewOptions.label,
        value: label,
      });
    }
    setExtra(formDataWithExtras, 'product_name', label);
    setExtra(formDataWithExtras, 'product_code', value);
    onChange(value);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ gap: '1rem' }}
      alignItems="center"
    >
      <Ae360Logo />
      <Typography variant="h6"> {uiOptions?.title}</Typography>
      <EntityPicker
        onValueChange={v => onSelect(v)}
        kind="System"
        label="Product"
      />
    </Box>
  );
};

export const WelcomeComponentExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'WelcomeComponent',
    schema: welcomeSchema.schema,
    component: WelcomeComponent,
    validation: async (fieldValue, validation, context) => {
      const { apiHolder, uiSchema, formData } = context;
      if (!fieldValue) {
        validation.addError('Required');
        return;
      }
      const configApi = apiHolder.get(configApiRef);
      const uiOptions: WelcomeComponentOptions = uiSchema?.[
        'ui:options'
      ] as any;

      const config: ProductMaturityConfig | undefined = configApi?.getOptional(
        'cyber.product-maturity',
      ) as ProductMaturityConfig;

      if (!configApi || !config) {
        return;
      }
      const result = await fetchPreviousAnswer(fieldValue, {
        options: uiOptions,
        config,
      });
      Object.assign(formData, result);
    },
  }),
);
