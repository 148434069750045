import React from 'react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import Box from '@material-ui/core/Box';
import logo from '../assets/everyday.png';

export function Ae360Logo() {
  return (
    <Box
      display="grid"
      width="100%"
      style={{
        placeItems: 'center',
      }}
    >
      <img src={logo} alt="logo" style={{ width: '128px' }} />
    </Box>
  );
}

export const Ae360LogoExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Ae360Logo',
    component: Ae360Logo as any,
  }),
);
