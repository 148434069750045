import React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  FormDataWithExtras,
  getExtra,
  getPreviewOptions,
  OutcomePillar,
} from '../extensions';
import { Ae360Logo } from '../Ae360Logo';
import { StepCollection } from './QuestionnaireResults/schema';

type PillarProps = {
  pillar: OutcomePillar;
  row: number;
  count: number;
  targetPosition: number;
  formData: any;
};

type CellProps = {
  text: string;
  column?: number;
  textColor?: string;
  row: number;
  fontWeight?: string;
  background?: string;
  isTarget?: boolean;
  isSelected?: boolean;
};
const Cell = (props: CellProps) => {
  const {
    isSelected,
    isTarget,
    text,
    background,
    column,
    row,
    fontWeight,
    textColor = '#000',
  } = props;
  let outline = isSelected ? `6px solid rgb(74 149 240)` : undefined;
  outline = !isSelected && isTarget ? `6px solid yellow` : outline;

  return (
    <Box
      gridColumn={column}
      gridRow={row}
      bgcolor={background || '#C3C3C3'}
      color={textColor}
      display="flex"
      alignItems="center"
      padding="0.5rem"
      fontWeight={fontWeight}
      sx={{
        border: outline,
      }}
    >
      {text}
    </Box>
  );
};

function Pillar(props: PillarProps) {
  const { pillar, count, targetPosition, row, formData } = props;
  const cells = new Array(count).fill(null);

  const preferedCellIndex = pillar.outcomes.findIndex(it => it.isTarget);
  const delta = targetPosition - preferedCellIndex;
  const sourceValue = formData[pillar.name];
  return (
    <>
      <Cell
        text={pillar.title}
        textColor="#FFF"
        fontWeight="bold"
        background="#2EB4A0FF"
        column={1}
        row={row}
      />
      {cells.map((_, idx) => {
        const outcome = pillar.outcomes[idx];
        return outcome ? (
          <Cell
            key={idx}
            row={row}
            text={outcome.title}
            isTarget={outcome.isTarget}
            isSelected={sourceValue === outcome.value}
            column={idx + delta}
          />
        ) : null;
      })}
    </>
  );
}

export interface OutcomesReviewProps {
  formData: FormDataWithExtras;
  steps: StepCollection;
}

export function OutcomesReview(props: OutcomesReviewProps) {
  const { formData, steps } = props;
  const { pillars } = getPreviewOptions(steps) ?? {};

  if (!pillars) {
    return <div> Invalid Schema</div>;
  }
  const title = getExtra<string>(formData, 'product_name');
  const code = getExtra<string>(formData, 'product_code');
  const cellCount = Math.max(...pillars.map(it => it.outcomes.length));
  const pillar = pillars.find(it => it.outcomes.length === cellCount);
  const targetPosition = pillar?.outcomes?.findIndex(it => it.isTarget) ?? -1;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ gap: '2rem' }}
    >
      <Ae360Logo />
      {title && (
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          {title} - {code}
        </Typography>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${cellCount + 1}, 1fr)`,
          gridGap: '0.5rem',
        }}
      >
        <Box component="h3" gridColumn={targetPosition + 2} gridRow={1}>
          Target
        </Box>
        {pillars.map((it, idx) => (
          <Pillar
            formData={formData}
            row={idx + 2}
            pillar={it}
            count={cellCount}
            targetPosition={targetPosition + 2}
          />
        ))}
      </Box>
      <Typography variant="body2">
        Thank you for self assessment! Please review this data as this will be
        submitted and reported in Power BI for your Division - press "SUBMIT" to
        send it now!
      </Typography>
    </Box>
  );
}
