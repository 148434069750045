import { PreviewOptions } from '../../../extensions';
import { orderBy } from 'lodash';
import { categoryMap, makeCounter } from './scorring-common';
import { QuestionnaireCategory } from '../../QuestionnaireResults/schema.ts';

export function getLadderScore(
  options: PreviewOptions,
  formData: Record<string, any>,
) {
  const { categories, mappings } = options;
  if (!categories || !mappings) {
    return {};
  }
  const map = categoryMap(categories);
  const mappingCollection = Object.entries(mappings);

  const counter = makeCounter(categories);
  const totals = { ...counter };

  mappingCollection.forEach(mapping => {
    const [key, values] = mapping;
    const foundValue = values.find(v => v.value === formData[key]);
    if (!foundValue) {
      return;
    }
    const currentCategory: QuestionnaireCategory = map[foundValue.category];
    const currentLevel = currentCategory?.level;
    if (currentLevel === undefined) {
      return;
    }
    categories
      .filter(c => c.level! <= currentLevel)
      .forEach(c => {
        counter[c.id] += 1;
      });
  });

  mappingCollection.forEach(mapping => {
    const [, values] = mapping;
    const highest = orderBy(
      values,
      v => {
        return map[v.category].level;
      },
      ['desc'],
    )[0];
    categories
      .filter(c => c.level! <= map[highest.category].level!)
      .forEach(c => {
        totals[c.id] += 1;
      });
  });

  return Object.fromEntries(
    categories.map(category => {
      return [category.id, (counter[category.id] / totals[category.id]) * 100];
    }),
  );
}
